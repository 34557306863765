<!-- 历史版本介绍 -->
<template>
  <div class="do-page-regulations">
    <base-nav-bar @click-left="router.go(-1)" left-arrow title="关于客流易">
    </base-nav-bar>
    <van-dialog
      v-model:show="showDialog"
      title="发现新版本"
      class="do-com-dialog dialog-regulations"
      confirm-button-text="去更新"
      @confirm="updataConfirm"
      show-cancel-button
    >
      <div class="tip">
        <br />
        {{ upVersionInfo.message }}
      </div>
    </van-dialog>
    <base-bscroll :safeareaNavbar="true" :options="{ bounce: true }">
      <div class="do-page-regulations-content">
        <div class="logo-wrap">
          <img :src="require('@/assets/images/my/logo-light.png')" />
        </div>
        <div class="title">客流易</div>
        <div class="tip">V{{ version }}</div>
        <!-- <div class="do-com-cellitem" @click="checkVersion">
          <div>检查新版本</div>
          <div class="right">
            <span :style="{ marginRight: '0.1rem' }" class="text"
              >V{{ version }}</span
            >
            <van-icon name="arrow" color="rgba(197, 197, 200, 1)" />
          </div>
        </div> -->
        <div
          class="do-com-cellitem"
          @click="
            router.push({
              path: '/my/regulations/versions',
            })
          "
        >
          <div>版本记录</div>
          <div class="right">
            <van-icon name="arrow" color="rgba(197, 197, 200, 1)" />
          </div>
        </div>
      </div>
    </base-bscroll>
    <div class="bottom-info">
      <div class="agreement">
        <span @click="plusAppUrl(1)">《用户服务协议》</span>
        <span @click="plusAppUrl(2)">《隐私协议》</span>
      </div>
      <div class="company">广州市梦享网络技术有限公司 版权所有</div>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
import appUpdata from "@/util/updata";
import { checkUpdateVersion } from "@/axios/common";
import { Toast } from "vant";
import { onMounted, ref, getCurrentInstance } from "vue";
import { useStore } from "vuex";
const { appContext } = getCurrentInstance();
const { $Hbuild } = appContext.config.globalProperties; //全局属性解构
const router = useRouter();
const store = useStore();
const version = ref(0);
const upVersionInfo = ref({});
const showDialog = ref(false);

function plusAppUrl(type) {
  const url =
    type === 1
      ? process.env.VUE_APP_USER_AGREEMENT_URL
      : process.env.VUE_APP_PRIVACY_AGREEMENT_URL;
  if (window.plus) {
    window.plus.runtime.openURL(url);
  } else {
    window.open(url);
  }
}

async function checkVersion() {
  try {
    const res = await checkUpdateVersion();
    console.log(" res.data.url", res.data.url);
    if (res && res.data.url) {
      upVersionInfo.value = res.data;
      showDialog.value = true;
    } else {
      Toast({
        position: "bottom",
        message: "已是最新版本",
      });
    }
  } catch (error) {}
}

function updataConfirm() {
  if (!upVersionInfo.value.url) return;
  appUpdata.downApk(upVersionInfo.value.url);
}

onMounted(() => {
  // 获取app 缓存大小
  version.value = $Hbuild.appVersion;
});
</script>

<style lang="scss">
.dialog-regulations {
  .van-dialog__content {
    text-align: center;
    font-size: 0.28rem;
    font-weight: 400;
    color: #969799;
  }
  .van-dialog__confirm {
    color: #0057ff;
  }
  .van-dialog__cancel {
    font-weight: 400;
    color: #323233;
  }
}
.do-page-regulations {
  .do-com-scroll-wrapper {
    background: $col_bg;
  }
  .do-com-navbar {
    &::after {
      border-bottom-width: 0px !important;
    }
  }
  .do-com-cellitem {
    width: 100%;
    margin-bottom: 0.24rem;
    font-size: 0.28;
    font-weight: 400;
    color: #02040f;
  }

  &-content {
    padding: 0rem 0.38rem;
    display: flex;
    flex-flow: column;
    align-items: center;
    .logo-wrap {
      height: 2.5rem;
      img {
        margin-top: 0.6rem;
        width: 2.16rem;
        height: 2.16rem;
      }
    }
    .title {
      font-size: 0.4rem;
      font-weight: 500;
      color: rgba(2, 4, 15, 0.85);
    }
    .tip {
      font-size: 0.28rem;
      font-weight: 400;
      color: rgba(2, 4, 15, 0.45);
      margin-bottom: 0.4rem;
      margin-top: 0.04rem;
    }
  }
  .bottom-info {
    position: fixed;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    bottom: 0.82rem;
    .agreement {
      font-size: 0.24rem;
      font-weight: 400;
      color: #0057ff;
      span {
      }
    }

    .company {
      margin-top: 0.08rem;
      font-size: 0.24rem;
      font-weight: 400;
      color: rgba(2, 4, 15, 0.25);
    }
  }
}
</style>
